<template>
  <b-overlay
    :show="isAPICallInProgress"
    rounded="sm"
  >
    <b-card>
      <validation-observer
        ref="personalData"
        #default="{invalid}"
      >
        <!-- form -->
        <b-form @submit.prevent="updateAccount">
          <b-row>
            <b-col sm="6">
              <validation-provider
                #default="{ errors }"
                name="nome"
                rules="required"
              >
                <b-form-group
                  label="Nome"
                  label-for="account-username"
                >
                  <b-form-input
                    v-model="formData.FullName"
                    placeholder="Nome"
                    name="username"
                    :state="errors.length > 0 ? false:null"
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col sm="6">
              <validation-provider
                #default="{ errors }"
                name="telefone"
                rules="required"
              >
                <b-form-group
                  label="Telefone"
                  label-for="account-company"
                >
                  <b-form-input
                    v-model="formData.Phone"
                    name="company"
                    placeholder="Telefone"
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col sm="6">
              <b-form-group
                label="E-mail"
                label-for="account-e-mail"
              >
                <b-form-input
                  v-model="formData.Email"
                  name="email"
                  placeholder="Email"
                  disabled
                />

              </b-form-group>
            </b-col>
            <b-col sm="6">
              <validation-provider
                #default="{ errors }"
                name="cpf"
                rules="required"
              >
                <b-form-group
                  label="CPF"
                  label-for="account-cpf"
                >
                  <b-form-input
                    v-model="formData.CPF"
                    name="cpf"
                    placeholder="CPF"
                    type="tel"
                    :disabled="jwtData.CPF !== null || jwtData.CPF !== '' "
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col cols="12">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mt-1 mr-1"
                :disabled="invalid"
              >
                Salvar
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </b-overlay>
</template>

<script>
import { mapGetters } from 'vuex'

import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BOverlay,
} from 'bootstrap-vue'

import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import { required } from '@validations'

import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'

import useJwt from '@/auth/jwt/useJwt'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BButton,
    BForm,
    BOverlay,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      // optionsLocal: JSON.parse(JSON.stringify(this.generalData)),
      jwtData: JSON.parse(localStorage.getItem('userData')),
      formData: JSON.parse(localStorage.getItem('userData')),
      profileFile: null,
      required,
    }
  },
  computed: {
    ...mapGetters({ isAPICallInProgress: 'api/isAPICallInProgress' }),
  },
  mounted() {
    localize('pt_BR')
  },
  methods: {
    updateAccount() {
      useJwt.updateAccount({
        userId: this.formData.UserId,
        name: this.formData.FullName,
        cellPhone: this.formData.Phone,
        cpf: this.formData.CPF,
      }).then(response => {
        const userDataProv = JSON.parse(localStorage.getItem('userData'))
        userDataProv.FullName = this.formData.FullName
        userDataProv.Phone = this.formData.Phone
        userDataProv.CPF = this.formData.CPF
        localStorage.setItem('userData', JSON.stringify(userDataProv))

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Seus dados foram alterados com sucesso!',
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        })

        return response
      })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ocorreu um erro. Tente novamente',
              icon: 'AlertOctagonIcon',
              variant: 'danger',
            },
          })
          return error
        })
    },
  },
  setup() {
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl)

    return {
      refInputEl,
      previewEl,
      inputImageRenderer,
    }
  },
}
</script>
