<template>
  <b-overlay
    :show="isAPICallInProgress"
    rounded="sm"
  >
    <b-card>
      <!-- form -->
      <validation-observer
        ref="changePassword"
        #default="{invalid}"
      >
        <b-form @submit.prevent="handleChangePassword">
          <b-row>
            <!-- old password -->
            <b-col md="6">
              <b-form-group
                label="Senha atual"
                label-for="account-old-password"
              >
                <validation-provider
                  #default="{ errors }"
                  name="senha atual"
                  vid="passwordValueOld"
                  rules="required"
                >
                  <b-input-group class="input-group-merge">
                    <b-form-input
                      id="account-old-password"
                      v-model="passwordValueOld"
                      name="old-password"
                      :type="passwordFieldTypeOld"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Senha atual"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIconOld"
                        class="cursor-pointer"
                        @click="togglePasswordOld"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!--/ old password -->
          </b-row>
          <b-row>
            <!-- new password -->
            <b-col md="6">
              <b-form-group
                label-for="account-new-password"
                label="Nova senha"
              >
                <validation-provider
                  #default="{ errors }"
                  name="senha nova"
                  vid="newPasswordValue"
                  rules="required|min:6"
                >
                  <b-input-group class="input-group-merge">
                    <b-form-input
                      id="account-new-password"
                      v-model="newPasswordValue"
                      :type="passwordFieldTypeNew"
                      :state="errors.length > 0 ? false:null"
                      name="new-password"
                      placeholder="Nova senha"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIconNew"
                        class="cursor-pointer"
                        @click="togglePasswordNew"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!--/ new password -->

            <!-- retype password -->
            <b-col md="6">
              <b-form-group
                label-for="account-retype-new-password"
                label="Repita a nova senha"
              >
                <validation-provider
                  #default="{ errors }"
                  name="repetir senha nova"
                  vid="RetypePassword"
                  rules="required|confirmed:newPasswordValue"
                >
                  <b-input-group class="input-group-merge">
                    <b-form-input
                      id="account-retype-new-password"
                      v-model="RetypePassword"
                      :type="passwordFieldTypeRetype"
                      :state="errors.length > 0 ? false:null"
                      name="retype-password"
                      placeholder="Nova senha"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIconRetype"
                        class="cursor-pointer"
                        @click="togglePasswordRetype"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!--/ retype password -->

            <!-- buttons -->
            <b-col cols="12">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mt-1 mr-1"
                :disabled="invalid"
              >
                Alterar senha
              </b-button>
            </b-col>
            <!--/ buttons -->
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </b-overlay>
</template>

<script>
import { mapGetters } from 'vuex'

import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BInputGroup, BInputGroupAppend, BOverlay,
} from 'bootstrap-vue'

import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'

import useJwt from '@/auth/jwt/useJwt'

import { required, min, confirmed } from '@validations'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BButton,
    BForm,
    BOverlay,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
    ValidationObserver,
    ValidationProvider,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      passwordValueOld: '',
      newPasswordValue: '',
      RetypePassword: '',
      passwordFieldTypeOld: 'password',
      passwordFieldTypeNew: 'password',
      passwordFieldTypeRetype: 'password',
      required,
      confirmed,
      min,
    }
  },
  computed: {
    passwordToggleIconOld() {
      return this.passwordFieldTypeOld === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    ...mapGetters({ isAPICallInProgress: 'api/isAPICallInProgress' }),
  },
  mounted() {
    localize('pt_BR')
  },
  methods: {
    togglePasswordOld() {
      this.passwordFieldTypeOld = this.passwordFieldTypeOld === 'password' ? 'text' : 'password'
    },
    togglePasswordNew() {
      this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype = this.passwordFieldTypeRetype === 'password' ? 'text' : 'password'
    },
    handleChangePassword() {
      this.$refs.changePassword.validate().then(success => {
        if (success) {
          useJwt.changePassword({
            password: this.passwordValueOld,
            newPassword: this.newPasswordValue,
            confirmPassword: this.RetypePassword,
          })
            .then(response => {
              if (response.data.successfullyProcessed) {
                this.$swal({
                  title: 'Senha alterada!',
                  text: 'Operação realizada com sucesso!',
                  icon: 'success',
                  showCancelButton: false,
                  confirmButtonText: 'OK',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })

                // resetando o form
                this.passwordValueOld = ''
                this.newPasswordValue = ''
                this.RetypePassword = ''
                this.$refs.changePassword.reset()
              } else {
                this.$swal({
                  title: 'Opss...',
                  text: 'Ocorreu um problema. Verifique os campos e tente novamente',
                  icon: 'error',
                  showCancelButton: false,
                  confirmButtonText: 'OK',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
              }

              return response
            })
            .catch(error => {
              this.$refs.changePassword.setErrors(error.response.data.messages[0])
            })
        }
      })
    },
  },
}
</script>
